import {
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Clear from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeviceSearchAutocomplete from "../../../Components/DeviceSearchAutocomplete";
import OrderSearchAutocomplete from "../../../Components/OrderSearchAutocomplete";
import StockItemSearch from "../../../Components/StockItems/StockItemSearch";
import Title from "../../../Components/Title";
import UserSearchAutocomplete from "../../../Components/UserSearchAutocomplete";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function EditEmailList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [emaillist, setEmailList] = useState();
  const list_id = getLastParameter();
  const dispatch = useDispatch();
  const [unedited, setUnedited] = useState();
  const [open, setOpen] = useState(false);
  const [resetKey, setResetKey] = useState("dsfafasd");

  const getEmailList = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list_id: list_id,
      }),
    };
    fetch(server + "/get_email_lists", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setEmailList(result.emaillist);
          setUnedited(result.emaillist);
        }
        if (result.error && result.message === "Invalid user") {
          window.location.replace("/cpanel/emaillists");
        }
      });
  };

  const submitList = (remove) => {
    if (JSON.stringify(emaillist) === JSON.stringify(unedited) && !remove) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list: emaillist,
        remove_list: remove,
      }),
    };
    fetch(server + "/edit_email_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (remove) {
            window.location.replace("/cpanel/emaillists");
            return;
          }
          window.location.reload();
        }
      });
  };

  useEffect(getEmailList, []);

  return (
    <Grid container spacing={3}>
      <Dialog open={open}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              submitList(true);
            }}
          >
            {t("yes")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/emaillists">
                  <Typography>{t("emailLists.header")}</Typography>
                </Link>
                <Typography>{t("emailLists.editEmailList")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("emailLists.editEmailList")}</Title>
            </Grid>
            <Grid item xs={12}>
              {emaillist ? (
                <TextField
                  variant="outlined"
                  label={t("emailLists.name")}
                  value={emaillist.name}
                  style={{ width: 300 }}
                  onChange={(e) => {
                    setEmailList({ ...emaillist, name: e.target.value });
                  }}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                {emaillist ? (
                  <FormControlLabel
                    defaultChecked={!!emaillist.allOrderAlerts}
                    control={
                      <Checkbox
                        onChange={(e, v) => {
                          setEmailList({
                            ...emaillist,
                            allOrderAlerts: v ? 1 : 0,
                          });
                        }}
                        defaultChecked={!!emaillist.allOrderAlerts}
                      />
                    }
                    label={t("emailLists.subscribeAllOrderAlerts")}
                  />
                ) : null}
              </Grid>
              <Grid item>
                {emaillist ? (
                  <FormControlLabel
                    defaultChecked={!!emaillist.allStockAlerts}
                    control={
                      <Checkbox
                        onChange={(e, v) => {
                          setEmailList({
                            ...emaillist,
                            allStockAlerts: v ? 1 : 0,
                          });
                        }}
                        defaultChecked={!!emaillist.allStockAlerts}
                      />
                    }
                    label={t("emailLists.subscribeAllStockAlerts")}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.users")}</Title>
              {unedited &&
                unedited.EmailListUsers &&
                unedited.EmailListUsers.map((u, i) =>
                  u ? (
                    <MenuItem
                      key={i}
                      style={
                        !emaillist.EmailListUsers.find(
                          (uu) => uu.User.emailAddress === u.User.emailAddress
                        )
                          ? { textDecoration: "line-through" }
                          : {}
                      }
                    >
                      {u.User.emailAddress}
                      <IconButton
                        style={{ position: "absolute", right: 0 }}
                        onClick={() => {
                          setEmailList({
                            ...emaillist,
                            EmailListUsers: emaillist.EmailListUsers.filter(
                              (f) => f.User.id !== u.User.id
                            ),
                          });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </MenuItem>
                  ) : null
                )}
              {emaillist &&
                unedited &&
                emaillist.EmailListUsers &&
                emaillist.EmailListUsers.filter(
                  (u) =>
                    !unedited.EmailListUsers.find(
                      (f) => f.User.id === u.User.id
                    )
                ).map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    {u.User.emailAddress}
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListUsers: emaillist.EmailListUsers.filter(
                            (f) => f.User.id !== u.User.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <UserSearchAutocomplete
                style={{ marginTop: 5 }}
                email={true}
                key={resetKey}
                label={t("emailLists.searchUser")}
                onChange={(e, v) => {
                  if (!v || !v.emailAddress) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListUsers: [...emaillist.EmailListUsers, { User: v }],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.devices")}</Title>
              {unedited &&
                unedited.EmailListDevices &&
                unedited.EmailListDevices.map((u, i) =>
                  u ? (
                    <MenuItem
                      key={i}
                      style={
                        emaillist.EmailListDevices.find(
                          (uu) =>
                            uu.Device.serialNumber === u.Device.serialNumber
                        )
                          ? {}
                          : { textDecoration: "line-through" }
                      }
                    >
                      {u.Device.serialNumber}
                      <IconButton
                        style={{ position: "absolute", right: 0 }}
                        onClick={() => {
                          setEmailList({
                            ...emaillist,
                            EmailListDevices: emaillist.EmailListDevices.filter(
                              (f) => f.Device.id !== u.Device.id
                            ),
                          });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </MenuItem>
                  ) : null
                )}
              {emaillist &&
                unedited &&
                emaillist.EmailListDevices &&
                emaillist.EmailListDevices.filter(
                  (u) =>
                    !unedited.EmailListDevices.find(
                      (f) => u.Device.id === f.Device.id
                    )
                ).map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    {u.Device.serialNumber}
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListDevices: emaillist.EmailListDevices.filter(
                            (f) => f.Device.id !== u.Device.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <DeviceSearchAutocomplete
                style={{ marginTop: 5 }}
                key={resetKey}
                label={t("emailLists.searchDevice")}
                onChange={(e, v) => {
                  if (!v || !v.serialNumber) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListDevices: [
                      ...emaillist.EmailListDevices,
                      { Device: v },
                    ],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.orders")}</Title>
              {unedited &&
                unedited.EmailListOrders &&
                unedited.EmailListOrders.map((u, i) =>
                  u ? (
                    <MenuItem
                      key={i}
                      style={
                        emaillist.EmailListOrders.find(
                          (uu) => uu.Order.number === u.Order.number
                        )
                          ? {}
                          : { textDecoration: "line-through" }
                      }
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <Typography style={{ fontSize: 15 }}>
                            {u.Order.number}
                          </Typography>
                        </Grid>
                        <Grid item container direction="row">
                          <Typography style={{ fontSize: 12 }}>
                            {u.Order.clientInfo.companyName}
                          </Typography>
                          <Typography
                            style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                          >
                            {" "}
                            {u.Order.clientInfo.location}
                          </Typography>
                          <Typography
                            style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                          >
                            {" "}
                            {u.Order.totalPrice} {u.Order.currency}
                          </Typography>
                        </Grid>
                      </Grid>
                      <IconButton
                        style={{ position: "absolute", right: 0 }}
                        onClick={() => {
                          setEmailList({
                            ...emaillist,
                            EmailListOrders: emaillist.EmailListOrders.filter(
                              (f) => f.Order.id !== u.Order.id
                            ),
                          });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </MenuItem>
                  ) : null
                )}
              {emaillist &&
                unedited &&
                emaillist.EmailListOrders &&
                emaillist.EmailListOrders.filter(
                  (u) =>
                    !unedited.EmailListOrders.length ||
                    !unedited.EmailListOrders.find(
                      (f) => u.Order.id === f.Order.id
                    )
                ).map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                      fontSize: 15,
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {u.Order.number}
                        </Typography>
                      </Grid>
                      <Grid item container direction="row">
                        <Typography style={{ fontSize: 12 }}>
                          {u.Order.clientInfo.companyName}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                        >
                          {" "}
                          {u.Order.clientInfo.location}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                        >
                          {" "}
                          {u.Order.totalPrice} {u.Order.currency}
                        </Typography>
                      </Grid>
                    </Grid>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListOrders: emaillist.EmailListOrders.filter(
                            (f) => f.Order.id !== u.Order.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <OrderSearchAutocomplete
                style={{ marginTop: 5 }}
                key={resetKey}
                additionalDataFields={[
                  "clientInfo.companyName",
                  "clientInfo.location",
                  "totalPrice",
                  "currency",
                ]}
                label={t("emailLists.searchOrder")}
                onChange={(e, v) => {
                  if (!v || !v.number) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListOrders: [
                      ...emaillist.EmailListOrders,
                      { Order: v },
                    ],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.stock")}</Title>
              {unedited &&
                unedited.EmailListStockItems &&
                unedited.EmailListStockItems.map((u) =>
                  u ? (
                    <MenuItem
                      style={
                        emaillist.EmailListStockItems.find(
                          (uu) => uu.StockItem.name === u.StockItem.name
                        )
                          ? {}
                          : { textDecoration: "line-through" }
                      }
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <Typography style={{ fontSize: 15 }}>
                            {u.StockItem.sku}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ fontSize: 12 }}>
                            {u.StockItem.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <IconButton
                        style={{ position: "absolute", right: 0 }}
                        onClick={() => {
                          setEmailList({
                            ...emaillist,
                            EmailListStockItems:
                              unedited.EmailListStockItems.filter(
                                (f) => f.StockItem.id !== u.StockItem.id
                              ),
                          });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </MenuItem>
                  ) : null
                )}
              {emaillist &&
                unedited &&
                emaillist.EmailListStockItems &&
                emaillist.EmailListStockItems.filter(
                  (u) =>
                    !unedited.EmailListStockItems.length ||
                    !unedited.EmailListStockItems.find(
                      (f) => u.StockItem.id === f.StockItem.id
                    )
                ).map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {u.StockItem.sku}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 12 }}>
                          {u.StockItem.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListStockItems:
                            emaillist.EmailListStockItems.filter(
                              (f) => f.StockItem.id !== u.StockItem.id
                            ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <StockItemSearch
                allowNewItems={false}
                clearOnSelect={true}
                includeEmailLists={true}
                label={t("emailLists.searchStockItem")}
                onChange={(e, v) => {
                  if (!v || !v.name) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListStockItems: [
                      ...emaillist.EmailListStockItems,
                      { StockItem: v },
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {emaillist ? (
                <>
                  <Button
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(emaillist)
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      submitList();
                    }}
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(emaillist)
                    }
                    variant="contained"
                    onClick={() => {
                      setEmailList(unedited);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
