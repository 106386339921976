import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Title from "../../Components/Title";
// import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

// const server = EnvSettings.server;

export default function SystemDashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("users.header")}</Title>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  history.push("/cpanel/users");
                }}
              >
                {t("system.viewUsers")}
              </Button>{" "}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  history.push("/cpanel/users/new");
                }}
              >
                {t("users.newUser")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("emailLists.header")}</Title>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  history.push("/cpanel/emaillists");
                }}
              >
                {t("emailLists.header")}
              </Button>{" "}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
