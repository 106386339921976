import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../../Components/SortableTable";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function EmailLists(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [emaillists, setLists] = useState();
  const dispatch = useDispatch();
  let getEmailLists = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_email_lists", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setLists(result.emaillist);
        }
      });
  };

  let showUser = (id) => {
    history.push("/cpanel/emaillists/" + id.id);
  };

  const handleScrollClick = (event, id) => {
    if (window.location.href.includes("/cpanel/")) {
      window.open("/cpanel/emaillists/" + id.id, "_blank");
    }
  };

  useEffect(getEmailLists, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Button
            onClick={() => {
              history.push("/cpanel/emaillists/new");
            }}
          >
            {t("emailLists.newList")}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {emaillists ? (
            <SortableTable
              headCells={[
                {
                  id: "name",
                  numeric: false,
                  disablePadding: false,
                  label: t("emailLists.name"),
                },
                {
                  id: "EmailListUsers",
                  numeric: false,
                  disablePadding: false,
                  label: t("emailLists.numberOfSubs"),
                },
              ]}
              defaultSortRow={"name"}
              handleRowClick={showUser}
              handleScrollClick={handleScrollClick}
              specialDataFormatting={[
                {
                  id: "EmailListUsers",
                  format: (data) => {
                    if (!data) {
                      return "";
                    }
                    return data.length;
                  },
                },
              ]}
              tableData={emaillists}
              rowsPerPage={25}
            />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
