import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  ECABIN,
  findValueInText,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function WebstoreOrders(props) {
  const classes = useStyles();
  const [searchList, setSearchList] = useState();
  const [orderList, setOrderList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterStates, setFilterStates] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const editBoxRef = useRef(null);

  const showOrder = (dev) => {
    history.push("/cpanel/webstore/" + dev.id);
  };

  const getWebstoreOrders = (ser_val) => {
    setLoading(true);
    setSearchList();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: searchValue || ser_val,
      }),
    };
    fetch(server + "/get_webstore_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setOrderList(result.orders);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // useEffect(getOrders, []);

  const filterResults = () => {
    setSearchList(
      orderList &&
        orderList.filter((contact) => {
          return (
            (filterStates &&
              (findValueInText(contact.number, searchValue) ||
                findValueInText(contact.genericDevices, searchValue))) ||
            (!filterStates &&
              // !findValueInText(contact.operator, "MUU RAHDINKULJETTAJA") &&
              !findValueInText(contact.number, "-") &&
              !isNaN(contact.number) &&
              (findValueInText(contact.number, searchValue) ||
                findValueInText(contact.genericDevices, searchValue)))
            //     ||
            // // filterStates ||
            // findValueInText(contact.number, searchValue) ||
            // findValueInText(contact.genericDevices, searchValue)
          );
        })
    );
  };

  useEffect(filterResults, [searchValue, orderList, filterStates]);

  useEffect(getWebstoreOrders, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("webstoreOrders.header")}</Title>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                label={t("search")}
                type="text"
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value.toLowerCase());
                }}
                ref={editBoxRef}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => {
                  // getOrders();
                }}
              >
                {t("search")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                defaultChecked={false}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e, v) => {
                      if (v) {
                        setFilterStates(true);
                        return;
                      }
                      setFilterStates(false);
                    }}
                    defaultChecked={false}
                  />
                }
                label={t("webstoreOrders.showUnrelated")}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {searchList ? (
            <>
              <SortableTable
                headCells={[
                  {
                    id: "createdAt",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdAt"),
                  },
                  {
                    id: "number",
                    numeric: false,
                    disablePadding: false,
                    label: t("webstoreOrders.number"),
                  },
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: false,
                    label: t("webstoreOrders.name"),
                  },
                  {
                    id: "operator",
                    numeric: false,
                    disablePadding: false,
                    label: t("webstoreOrders.operator"),
                  },
                  {
                    id: "trackingNumber",
                    numeric: false,
                    disablePadding: false,
                    label: t("webstoreOrders.trackingNumber"),
                  },
                  {
                    id: "shippingDate",
                    numeric: false,
                    disablePadding: false,
                    label: t("webstoreOrders.shippingDate"),
                  },
                ]}
                // disablePagination: true,
                defaultSortRow={"createdAt"}
                handleRowClick={showOrder}
                getRowStyle={(data) => {
                  var obj = { cursor: "pointer" };
                  if (
                    data.paymentStatus === "deposit" ||
                    data.paymentStatus === "completed"
                  ) {
                    return { ...obj, backgroundColor: "#ccffcc" };
                  }
                  if (data.paymentStatus === "delivered") {
                    return { ...obj, backgroundColor: "limegreen" };
                  }
                  if (data.paymentStatus === "cancelled") {
                    return { ...obj, backgroundColor: "lightgrey" };
                  }
                  return obj;
                }}
                specialDataFormatting={[
                  {
                    id: "createdAt",
                    format: (data) => {
                      return formatTimeWithTZOffset(data, ECABIN);
                    },
                  },
                ]}
                // handleScrollClick: showDeviceNewTab,
                tableData={searchList}
                rowsPerPage={50}
              />
            </>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
