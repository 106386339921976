import {
  Breadcrumbs,
  Button,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NoteSection from "../../Components/NoteSection";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function EditWebstoreOrder(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState();
  const order_id = getLastParameter();
  const dispatch = useDispatch();
  const [unedited, setUnedited] = useState();
  const editables = ["trackingNumber"];

  const getWebstoreOrder = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        order_id: order_id,
      }),
    };
    fetch(server + "/get_webstore_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setOrder(result.order);
          setUnedited(result.order);
        }
        if (result.error && result.message === "Invalid user") {
          window.location.replace("/cpanel/webstore");
        }
      });
  };

  const checkOperator = (value) => {
    if (!value) {
      return unedited.operator;
    }
    if (value && value.includes("JJFI")) {
      if (order && !order.operator.includes("Posti")) {
        return "Posti Oy";
      }
    } else {
      if (order && order.operator.includes("Posti")) {
        return "DHL Express";
      }
    }
  };

  const timEdit = (k) => {
    var [d, tim] = order[k].split(" ");
    d = d.replaceAll(".", "/").replace(/([0-9]+)\/([0-9]+)/, "$2/$1");
    tim = tim.replaceAll(".", ":");
    var dat = Date.parse(d + " " + tim);
    return formatTimeWithTZOffset(new Date(dat).toISOString(), ECABIN);
  };

  const submitWebstoreOrder = (remove) => {
    if (JSON.stringify(order) === JSON.stringify(unedited) && !remove) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        order: order,
        remove_list: remove,
      }),
    };
    fetch(server + "/edit_webstore_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (remove) {
            window.location.replace("/cpanel/webstore");
            return;
          }
          window.location.reload();
        }
      });
  };

  useEffect(getWebstoreOrder, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/webstore">
                  <Typography>{t("webstoreOrders.header")}</Typography>
                </Link>
                <Typography>{t("webstoreOrders.editWebstoreOrder")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("webstoreOrders.editWebstoreOrder")}</Title>
            </Grid>
            <Grid container item xs={12} spacing={1} direction="column">
              {order
                ? Object.keys(order).map((k) =>
                    k !== "Notes" ? (
                      <Grid item>
                        <TextField
                          variant={
                            !!editables.find((f) => f === k)
                              ? "outlined"
                              : unedited && order[k] !== unedited[k]
                              ? "filled"
                              : undefined
                          }
                          disabled={!editables.find((f) => f === k)}
                          label={t("webstoreOrders." + k)}
                          value={
                            k === "createdAt" || k === "updatedAt"
                              ? formatTimeWithTZOffset(order[k], ECABIN)
                              : k === "shippingDate"
                              ? timEdit(k)
                              : order[k]
                          }
                          style={{ width: 300 }}
                          onChange={(e) => {
                            var v = checkOperator(e.target.value);
                            if (v) {
                              v = {
                                ...order,
                                operator: v,
                                [k]: e.target.value,
                              };
                            } else {
                              v = { ...order, [k]: e.target.value };
                            }
                            setOrder(v);
                          }}
                        />
                      </Grid>
                    ) : null
                  )
                : null}
            </Grid>
            <Grid item xs={12}>
              {order ? (
                <>
                  <Button
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(order)
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      submitWebstoreOrder();
                    }}
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(order)
                    }
                    variant="contained"
                    onClick={() => {
                      setOrder(unedited);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
          {order ? (
            <NoteSection
              notes={order.Notes}
              parent={{ WebstoreOrderId: order.id }}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
}
