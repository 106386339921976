import { Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";

const server = EnvSettings.server;

const genericDevices = [
  { serialNumber: "GENERIC ECABIN" },
  { serialNumber: "GENERIC XCRYO" },
  { serialNumber: "GENERIC XTONE" },
  { serialNumber: "GENERIC LEDPRO" },
  { serialNumber: "GENERIC OXYPRO" },
];

const getFields = (data, key) => {
  if (!key || !data) {
    return;
  }
  var arr;
  if (key.includes(".")) {
    arr = key.split(".");
  }
  if (!arr) {
    arr = [key];
  }
  var value;
  arr.forEach((element, i) => {
    if (i === 0) {
      value = Object.keys(data).find((a) => a === element) ? data[element] : "";
    } else {
      value = Object.keys(value).find((a) => a === element)
        ? value[element]
        : "";
    }
    if (value && Array.isArray(value) && value.length) {
      value = value.sort((a, b) => b.id - a.id)[0];
    }
  });
  if (key.includes("quality") && value) {
    return "Class: " + value;
  }
  return value || "";
};

export default function DeviceSearchAutocomplete({
  onChange,
  allowGenericDevices,
  allowNewDevices,
  scrollIntoView,
  freeSearchFunction,
  searchRestrictions,
  additionalDataFields,
  fetchDevicesOnFocus,
  onFocus,
  label,
}) {
  const dispatch = useDispatch();
  const [devicesList, setDevicesList] = useState([]);
  const [loadingDevicesList, setLoadingDevicesList] = useState(false);
  const { t } = useTranslation();
  const additionalOptions = allowGenericDevices ? genericDevices : [];
  const filter = createFilterOptions();

  const getDevices = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingDevicesList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
        requireLCS: false,
        ...(searchRestrictions ? searchRestrictions : {}),
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setDevicesList(result.devices);
        }
        setLoadingDevicesList(false);
      });
  };
  return (
    <Grid item xs={12}>
      <Autocomplete
        id="devices"
        options={[...devicesList, ...additionalOptions]}
        fullWidth
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        // getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.serialNumber;
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment key={option.sku}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  {option.serialNumber}
                </Typography>
              </Grid>
              {additionalDataFields
                ? additionalDataFields.map((m, i) => (
                    <Grid key={i} item xs={12}>
                      <Typography style={{ fontSize: 12 }}>
                        {getFields(option, m)}
                      </Typography>
                    </Grid>
                  ))
                : null}
            </Grid>
          </React.Fragment>
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, {
            ...params,
            inputValue: params.inputValue.replace(" ", ""),
          });

          // Suggest the creation of a new value
          if (params.inputValue !== "" && !filtered.length) {
            var v = params.inputValue.replace(" ", "");
            filtered.push({
              inputValue: "Add Device " + v,
              serialNumber: v,
              LifeCycleStates: [],
            });
          }
          return filtered;
        }}
        loading={loadingDevicesList}
        freeSolo={allowNewDevices}
        renderInput={(params) => (
          <TextField
            {...params}
            // defaultValue={defaultValue || ""}
            label={label || t("device")}
            placeholder="e.g EC220002"
            variant="outlined"
          />
        )}
        onFocus={() => {
          if (scrollIntoView) {
            scrollIntoView();
          }
          if (fetchDevicesOnFocus) {
            getDevices(fetchDevicesOnFocus);
          }
          if (onFocus) {
            onFocus();
          }
        }}
        onChange={onChange}
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase().replace(/\s/g, "");
          }
          getDevices(v);
          if (freeSearchFunction) {
            freeSearchFunction(v);
          }
          // getClientInfo(v);
        }}
        onBlurCapture={allowNewDevices ? onChange : () => {}}
      />
    </Grid>
  );
}
