import { Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";

const server = EnvSettings.server;

const getFields = (data, key) => {
  if (!key || !data) {
    return;
  }
  var arr;
  if (key.includes(".")) {
    arr = key.split(".");
  }
  if (!arr) {
    arr = [key];
  }
  var value;
  arr.forEach((element, i) => {
    if (i === 0) {
      value = Object.keys(data).find((a) => a === element) ? data[element] : "";
    } else {
      value = Object.keys(value).find((a) => a === element)
        ? value[element]
        : "";
    }
    if (value && Array.isArray(value) && value.length) {
      value = value.sort((a, b) => b.id - a.id)[0];
    }
  });
  if (key.includes("quality") && value) {
    return "Class: " + value;
  }
  return value || "";
};

export default function OrderSearchAutocomplete({
  onChange,
  userGroup,
  required,
  defaultValue,
  label,
  email,
  style,
  additionalDataFields,
}) {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const { t } = useTranslation();

  const getUsers = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingUsersList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        // search_value: ser_val,
      }),
    };
    fetch(server + "/get_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setUsersList(result.orders);
        }
        setLoadingUsersList(false);
      });
  };
  return (
    <Grid item xs={12} style={style || {}}>
      <Autocomplete
        id="orders"
        options={[...usersList].filter(
          (f) => f && f.paymentStatus !== "delivered"
        )}
        fullWidth
        getOptionSelected={(option, value) => {
          return option.number === value.number;
        }}
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        getOptionLabel={(option) => {
          if (!option || !option.number) {
            return "";
          }
          return option.number;
        }}
        loading={loadingUsersList}
        defaultValue={{ number: defaultValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || t("orders.order")}
            variant="outlined"
            required={required}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment key={option.sku}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  {option.number}
                </Typography>
              </Grid>
              {additionalDataFields
                ? additionalDataFields.map((m, i) => (
                    <Grid key={i} item xs={12}>
                      <Typography style={{ fontSize: 12 }}>
                        {getFields(option, m)}
                      </Typography>
                    </Grid>
                  ))
                : null}
            </Grid>
          </React.Fragment>
        )}
        onChange={onChange}
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase();
          }
          getUsers(v);
          // getClientInfo(v);
        }}
      />
    </Grid>
  );
}
