import { MenuItem, Tooltip, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @brief Session error codes are presented in binary allowing multiple error codes to be saved in single integer
 *
 */
const Session_error_codes = {
  interrupted_from_ui: 0b00000001,
  interrupted_from_door: 0b00000010,
  high_ambient_temperature: 0b00000100,
  high_humidity: 0b00001000,
  session_failed: 0b00010000,
};

const checkBit = (target, position, value) => {
  return ((target >> (position - 1)) & 1) === value;
};

const getErrorCodes = (value) => {
  if (value > 100) {
    return [];
  }
  var arr = [];
  Object.keys(Session_error_codes).forEach((k, i) => {
    if (checkBit(value, i + 1, 1)) {
      arr.push(k);
    }
  });
  return arr;
};

export default function ErrorCodeItem({ data }) {
  const { t } = useTranslation();
  const [errCodes, setErrCodes] = useState();

  useEffect(() => {
    if (data) {
      setErrCodes(getErrorCodes(data));
    }
  }, [data]);

  return errCodes && errCodes.length ? (
    <Grid container direction="column">
      {errCodes.map((m) => (
        <Tooltip
          title={
            <Typography style={{ fontSize: 15 }}>
              {t("sessionErrorCodes." + m + ".description")}
            </Typography>
          }
          placement={"top"}
        >
          <MenuItem>{t("sessionErrorCodes." + m + ".name")}</MenuItem>
        </Tooltip>
      ))}
    </Grid>
  ) : null;
}
